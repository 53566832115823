<template>
  <div>
    <div class="info" @click="editChildInfo">
      <div class="img">
        <img v-if="info.gender == 0" src="../../common/img/girl.png" />
        <img v-if="info.gender == 1" src="../../common/img/boy.png" />
      </div>
      <div style="margin: 49px 20px 0 0">
        <div class="name">
          <div>{{ info.name }}</div>
          &nbsp;&nbsp;
          <img v-if="info.gender == 0" src="../../common/img/female.png" />
          <img
            v-if="info.gender == 1"
            src="../../common/img/male.png"
          />&nbsp;&nbsp;
          <div>{{ info.age }}岁</div>
        </div>
        <div class="kindergarten">{{ info.createName }}</div>
      </div>
    </div>
    <div style="margin: 10px 20px 0 20px">
      <van-cell
        title="切换孩子"
        is-link
        to="changeChild"
        style="border-top-left-radius: 7px; border-top-right-radius: 7px"
      >
        <template #icon>
          <img class="img" src="../../common/img/change.png" />
        </template>
      </van-cell>
      <van-cell title="增加孩子" is-link @click="addStu">
        <template #icon>
          <img class="img" src="../../common/img/bind.png" />
        </template>
      </van-cell>
      <van-cell
        title="解绑孩子"
        is-link
        @click="relieve()"
        style="border-bottom-left-radius: 7px; border-bottom-right-radius: 7px"
      >
        <template #icon>
          <img class="img" src="../../common/img/unbind.png" />
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script>
import api from '../../common/js/api';
import { Dialog, Toast } from 'vant';
export default {
  data() {
    return {
      id: '',
      info: {},
    };
  },
  created() {
    this.id = window.sessionStorage.getItem('childId');
    this.getInfo();
  },
  components: {},

  computed: {},

  mounted() {},

  methods: {
    addStu() {
      this.$router.push({ path: '/', query: { type: 1 } });
    },
    relieve() {
      Dialog.confirm({
        title: '提示',
        message: '确定要解绑孩子吗',
      })
        .then(() => {
          this.$post(api.unbindService, { id: this.id }).then((res) => {
            if (res.success) {
              Toast('解绑成功');
              this.getStuList();
            } else {
              this.$toast(res.message);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    getStuList() {
      this.$post(api.getStuList, { origin: 1 }).then((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            if (res.data.length > 0) {
              window.sessionStorage.setItem(
                'childList',
                JSON.stringify(res.data)
              );
              window.sessionStorage.setItem('childId', res.data[0].id);
              this.id = res.data[0].id;
              this.getInfo();
            } else {
              this.$router.replace('/bind');
            }
          } else {
            this.$router.replace('/bind');
          }
        } else {
          this.$toast(res.message);
        }
      });
    },
    getInfo() {
      this.$post(api.childInfoService, {
        id: this.id,
      }).then((res) => {
        this.info = res.data;
      });
    },
    editChildInfo() {
      this.$router.push('/childInfoEdit');
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  background: white;
  height: 190px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(#1e78ff, #72c2ff);
  .img {
    width: 60px;
    height: 60px;
    margin: 49px 20px 0 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .name {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    font-weight: bold;
    font-size: 17px;
    color: white;
    img {
      width: 13px;
      height: 16px;
    }
  }
  .kindergarten {
    font-size: 15px;
    color: #abdaff;
  }
}
/deep/.van-cell {
  align-items: center;
}
.img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
</style>
